
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as faqG49zTqpZg_1aX_ZzzwXdO0H6EfiWoymFrrv9_G_Ewd4Meta } from "/build/pages/faq.vue?macro=true";
import { default as errorgVDmNYtt5P68tr5vRzw2PCADHN5Nba7pP4vJJMvUVP8Meta } from "/build/pages/error.vue?macro=true";
import { default as indexfaSkdcsHLeb812gzXZAgK_hEZEnndCv3Vs4JNO6v_aQMeta } from "/build/pages/index.vue?macro=true";
import { default as loginUvCkxHkFyJ7GYIEdAUdWNr1bx6z3WHdIuYF72ilYgXIMeta } from "/build/pages/login.vue?macro=true";
import { default as sentryg2GiS0f3w4oenpvpv8b1gruQds5BP1EvAMsLfuBJC_0Meta } from "/build/pages/sentry.vue?macro=true";
import { default as verify6MAOBW4Olce77DrTToZae1A_45SMHUqQhb7hTWyvCNm_8Meta } from "/build/pages/verify.vue?macro=true";
import { default as verifiedNgc6cryABiqQlr58jnbu6WIhjtbeTDAHnqvMfe0aj_45UMeta } from "/build/pages/verified.vue?macro=true";
import { default as _91slug_93Rm_450MY9cXxHNT637NJwV_nketYgfV3mDnfeKBNb77NAMeta } from "/build/pages/cms/[slug].vue?macro=true";
import { default as two_45factorWEgWwseOk2ixurervDN5Po0r3B2sG5SWm4sPfgO4c18Meta } from "/build/pages/two-factor.vue?macro=true";
import { default as card5VqFVxBbiHzU8RLBhbmSVuV1eIcCl2AjubtiQpxxVw4Meta } from "/build/pages/portal/card.vue?macro=true";
import { default as helpI_45ywGXhb7T1I8_45YlEtlLBGhUzZDonNBNv8gwgrPD_45_UMeta } from "/build/pages/portal/help.vue?macro=true";
import { default as set_45passwordyOXEzayEPbqcZTU_456fCtLhvQ5xavBlHbG6Qjag08efUMeta } from "/build/pages/set-password.vue?macro=true";
import { default as email_45confirm_45JH_T0CAhf0I7oBDCv9V9pJ2BkU_45KyzIGaXuwEwc3nUMeta } from "/build/pages/email-confirm.vue?macro=true";
import { default as eventsJ6Wn03HgQysl0TsfoMcbBEKlkG4Aaiit76U6vDa_f_sMeta } from "/build/pages/portal/events.vue?macro=true";
import { default as logoutNCLKptZJ8q3ZR4Gr6pxPzC2fDFNJFHGydxv6rsM_skQMeta } from "/build/pages/portal/logout.vue?macro=true";
import { default as statusWe9sCnoWsszKR6KoXEgLgSfWaYPI02bh8AKJ8oX5BSQMeta } from "/build/pages/portal/status.vue?macro=true";
import { default as accountAgjzQbkQwgsTY_45Xx8xlVrCg9_45fRRbnlYgBZ2BKgYB24Meta } from "/build/pages/portal/account.vue?macro=true";
import { default as parkingmv_45zj1uy8n5FVd6QbTei_45EMv7BrnHkHj_45VO4AixjffgMeta } from "/build/pages/portal/parking.vue?macro=true";
import { default as rewardsj2gk_DXrKv2lO_45oQmHnGCxGOCIAc72SuyJqRLLyNOZIMeta } from "/build/pages/portal/rewards.vue?macro=true";
import { default as register_45mergeMP_45DUJ_45IupsCjf9CasHPsRxTSFvQqJgq7JG3ytVT0E8Meta } from "/build/pages/register-merge.vue?macro=true";
import { default as indexcCDjqIgIZHjhkjq4EhUY_45gLRzkXmnKqCsvhtlQlKBc0Meta } from "/build/pages/register/index.vue?macro=true";
import { default as forgot_45passwordUwhTQYJO5SwnV0ggK4qrWIdgqKQiXtTMlKDV3AAHFS8Meta } from "/build/pages/forgot-password.vue?macro=true";
import { default as register_45tabletgN6ieBFVijEIyyF6__45lo8Z1BIXS8AzvYjW5fSqJNiwgMeta } from "/build/pages/register-tablet.vue?macro=true";
import { default as forgot_45password_45sent0myb6aih9JArogRi2_454LSZ_45wvQNaG6uW_Pj_45Po_45qgswMeta } from "/build/pages/forgot-password-sent.vue?macro=true";
import { default as indexSNLrSq0PMghY62VDfDid3fxJ3z_t1q8NKbJQQAllsdsMeta } from "/build/pages/portal/coupons/index.vue?macro=true";
import { default as notifications0_45mwRA2SVn1YPg7Axv8xku5OaS3T_451Cn0A2JaT2jULYMeta } from "/build/pages/portal/notifications.vue?macro=true";
import { default as _91code_93qW_45UtfaeSm7kgg1LJa_45qhi10yiIGaoJiuH_45zVMhmHNMMeta } from "/build/pages/portal/coupons/[code].vue?macro=true";
import { default as reset_45password_45successoo_45YVA_TKIVYUBV3C8MCw3H9o6g89mHzGPIs0Yj1WnoMeta } from "/build/pages/reset-password-success.vue?macro=true";
import { default as register_45tablet_45success_vigTLPo0aCCWqphzMLW09NzStO7Zh34wp5BTRWHruIMeta } from "/build/pages/register-tablet-success.vue?macro=true";
import { default as _91token_93w_jlk8o_45M0whKuzCR_45SMsmaORoUy_45Qqqh8oE4Gw8okIMeta } from "/build/pages/reset-password/[email]/[token].vue?macro=true";
import { default as _91id_93uUVGbJQbo4Ov_45_45yrgtJZvicuG2wJ7DnjH_31y7X7ExEMeta } from "/build/pages/register/referral/employee/[id].vue?macro=true";
export default [
  {
    name: "faq___de",
    path: "/faq",
    meta: faqG49zTqpZg_1aX_ZzzwXdO0H6EfiWoymFrrv9_G_Ewd4Meta || {},
    component: () => import("/build/pages/faq.vue")
  },
  {
    name: "error___de",
    path: "/error",
    component: () => import("/build/pages/error.vue")
  },
  {
    name: "index___de",
    path: "/",
    meta: indexfaSkdcsHLeb812gzXZAgK_hEZEnndCv3Vs4JNO6v_aQMeta || {},
    component: () => import("/build/pages/index.vue")
  },
  {
    name: "login___de",
    path: "/login",
    meta: loginUvCkxHkFyJ7GYIEdAUdWNr1bx6z3WHdIuYF72ilYgXIMeta || {},
    component: () => import("/build/pages/login.vue")
  },
  {
    name: "sentry___de",
    path: "/sentry",
    component: () => import("/build/pages/sentry.vue")
  },
  {
    name: "verify___de",
    path: "/verify",
    meta: verify6MAOBW4Olce77DrTToZae1A_45SMHUqQhb7hTWyvCNm_8Meta || {},
    component: () => import("/build/pages/verify.vue")
  },
  {
    name: "verified___de",
    path: "/verified",
    meta: verifiedNgc6cryABiqQlr58jnbu6WIhjtbeTDAHnqvMfe0aj_45UMeta || {},
    component: () => import("/build/pages/verified.vue")
  },
  {
    name: "cms-slug___de",
    path: "/cms/:slug()",
    meta: _91slug_93Rm_450MY9cXxHNT637NJwV_nketYgfV3mDnfeKBNb77NAMeta || {},
    component: () => import("/build/pages/cms/[slug].vue")
  },
  {
    name: "two-factor___de",
    path: "/two-factor",
    meta: two_45factorWEgWwseOk2ixurervDN5Po0r3B2sG5SWm4sPfgO4c18Meta || {},
    component: () => import("/build/pages/two-factor.vue")
  },
  {
    name: "portal-card___de",
    path: "/portal/card",
    meta: card5VqFVxBbiHzU8RLBhbmSVuV1eIcCl2AjubtiQpxxVw4Meta || {},
    component: () => import("/build/pages/portal/card.vue")
  },
  {
    name: "portal-help___de",
    path: "/portal/help",
    meta: helpI_45ywGXhb7T1I8_45YlEtlLBGhUzZDonNBNv8gwgrPD_45_UMeta || {},
    component: () => import("/build/pages/portal/help.vue")
  },
  {
    name: "set-password___de",
    path: "/set-password",
    meta: set_45passwordyOXEzayEPbqcZTU_456fCtLhvQ5xavBlHbG6Qjag08efUMeta || {},
    component: () => import("/build/pages/set-password.vue")
  },
  {
    name: "email-confirm___de",
    path: "/email-confirm",
    meta: email_45confirm_45JH_T0CAhf0I7oBDCv9V9pJ2BkU_45KyzIGaXuwEwc3nUMeta || {},
    component: () => import("/build/pages/email-confirm.vue")
  },
  {
    name: "portal-events___de",
    path: "/portal/events",
    meta: eventsJ6Wn03HgQysl0TsfoMcbBEKlkG4Aaiit76U6vDa_f_sMeta || {},
    component: () => import("/build/pages/portal/events.vue")
  },
  {
    name: "portal-logout___de",
    path: "/portal/logout",
    meta: logoutNCLKptZJ8q3ZR4Gr6pxPzC2fDFNJFHGydxv6rsM_skQMeta || {},
    component: () => import("/build/pages/portal/logout.vue")
  },
  {
    name: "portal-status___de",
    path: "/portal/status",
    meta: statusWe9sCnoWsszKR6KoXEgLgSfWaYPI02bh8AKJ8oX5BSQMeta || {},
    component: () => import("/build/pages/portal/status.vue")
  },
  {
    name: "portal-account___de",
    path: "/portal/account",
    meta: accountAgjzQbkQwgsTY_45Xx8xlVrCg9_45fRRbnlYgBZ2BKgYB24Meta || {},
    component: () => import("/build/pages/portal/account.vue")
  },
  {
    name: "portal-parking___de",
    path: "/portal/parking",
    meta: parkingmv_45zj1uy8n5FVd6QbTei_45EMv7BrnHkHj_45VO4AixjffgMeta || {},
    component: () => import("/build/pages/portal/parking.vue")
  },
  {
    name: "portal-rewards___de",
    path: "/portal/rewards",
    meta: rewardsj2gk_DXrKv2lO_45oQmHnGCxGOCIAc72SuyJqRLLyNOZIMeta || {},
    component: () => import("/build/pages/portal/rewards.vue")
  },
  {
    name: "register-merge___de",
    path: "/register-merge",
    meta: register_45mergeMP_45DUJ_45IupsCjf9CasHPsRxTSFvQqJgq7JG3ytVT0E8Meta || {},
    component: () => import("/build/pages/register-merge.vue")
  },
  {
    name: "register___de",
    path: "/register",
    meta: indexcCDjqIgIZHjhkjq4EhUY_45gLRzkXmnKqCsvhtlQlKBc0Meta || {},
    component: () => import("/build/pages/register/index.vue")
  },
  {
    name: "forgot-password___de",
    path: "/forgot-password",
    meta: forgot_45passwordUwhTQYJO5SwnV0ggK4qrWIdgqKQiXtTMlKDV3AAHFS8Meta || {},
    component: () => import("/build/pages/forgot-password.vue")
  },
  {
    name: "register-tablet___de",
    path: "/register-tablet",
    meta: register_45tabletgN6ieBFVijEIyyF6__45lo8Z1BIXS8AzvYjW5fSqJNiwgMeta || {},
    component: () => import("/build/pages/register-tablet.vue")
  },
  {
    name: "forgot-password-sent___de",
    path: "/forgot-password-sent",
    meta: forgot_45password_45sent0myb6aih9JArogRi2_454LSZ_45wvQNaG6uW_Pj_45Po_45qgswMeta || {},
    component: () => import("/build/pages/forgot-password-sent.vue")
  },
  {
    name: "portal-coupons___de",
    path: "/portal/coupons",
    meta: indexSNLrSq0PMghY62VDfDid3fxJ3z_t1q8NKbJQQAllsdsMeta || {},
    component: () => import("/build/pages/portal/coupons/index.vue")
  },
  {
    name: "portal-notifications___de",
    path: "/portal/notifications",
    meta: notifications0_45mwRA2SVn1YPg7Axv8xku5OaS3T_451Cn0A2JaT2jULYMeta || {},
    component: () => import("/build/pages/portal/notifications.vue")
  },
  {
    name: "portal-coupons-code___de",
    path: "/portal/coupons/:code()",
    meta: _91code_93qW_45UtfaeSm7kgg1LJa_45qhi10yiIGaoJiuH_45zVMhmHNMMeta || {},
    component: () => import("/build/pages/portal/coupons/[code].vue")
  },
  {
    name: "reset-password-success___de",
    path: "/reset-password-success",
    meta: reset_45password_45successoo_45YVA_TKIVYUBV3C8MCw3H9o6g89mHzGPIs0Yj1WnoMeta || {},
    component: () => import("/build/pages/reset-password-success.vue")
  },
  {
    name: "register-tablet-success___de",
    path: "/register-tablet-success",
    meta: register_45tablet_45success_vigTLPo0aCCWqphzMLW09NzStO7Zh34wp5BTRWHruIMeta || {},
    component: () => import("/build/pages/register-tablet-success.vue")
  },
  {
    name: "reset-password-email-token___de",
    path: "/reset-password/:email()/:token()",
    meta: _91token_93w_jlk8o_45M0whKuzCR_45SMsmaORoUy_45Qqqh8oE4Gw8okIMeta || {},
    component: () => import("/build/pages/reset-password/[email]/[token].vue")
  },
  {
    name: "register-referral-employee-id___de",
    path: "/register/referral/employee/:id()",
    component: () => import("/build/pages/register/referral/employee/[id].vue")
  }
]