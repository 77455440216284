import * as braze from "@braze/web-sdk";
import type { KadeweBrazeEvent, KadeweUser } from "~/types";

export default defineNuxtPlugin(() => {
    const {
        public: {
            braze: { api_key: apiKey, base_url: baseUrl, logging: enableLogging },
        },
    } = useRuntimeConfig();
    const user = useSanctumUser<KadeweUser>();

    return {
        provide: {
            braze: {
                initialize: () => {
                    if (!apiKey) {
                        console.warn("Braze API key is not set");
                        return;
                    }

                    braze.initialize(apiKey, {
                        baseUrl: baseUrl,
                        enableLogging: enableLogging,
                        openCardsInNewTab: true,
                        localization: user.value?.language,
                    });

                    if (user.value?.contact_id) {
                        braze.changeUser(user.value.contact_id);
                    }

                    braze.openSession();
                },
                getCachedContentCards: (): KadeweBrazeEvent[] => {
                    // @ts-expect-error Braze types are not correct
                    return braze.getCachedContentCards().cards as KadeweBrazeEvent[];
                },
                requestContentCardsRefresh: async () => {
                    return new Promise<void>((resolve, reject) => {
                        braze.requestContentCardsRefresh(
                            () => {
                                console.debug("Content cards successfully refreshed.");
                                resolve();
                            },
                            () => {
                                console.error("Failed to refresh content cards!");
                                reject();
                            },
                        );
                    });
                },
            },
        },
    };
});
