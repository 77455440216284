<template>
    <layout-header />

    <div class="flex flex-col items-center w-full px-8 mt-2 md:mt-10">
        <img
            v-if="error && error?.statusCode >= 500"
            src="/images/error/5xx.png"
            :alt="$t('error.500.alt_image')"
            class="max-w-3xl w-full h-auto"
        />
        <img
            v-else-if="error && error?.statusCode === 404"
            src="/images/error/404.png"
            :alt="$t('error.404.alt_image')"
            class="max-w-52 md:max-w-md w-full h-auto mt-20"
        />
        <img
            v-else
            src="/images/error/generic.png"
            :alt="$t('error.generic.alt_image')"
            class="max-w-52 md:max-w-xl w-full h-auto mt-20"
        />

        <div class="max-w-xl">
            <h2
                v-if="error && error.statusCode >= 500"
                class="text-10xl md:text-16xl mt-4 mb-10 md:mt-0 md:mb-16"
                v-text="$t('error.500.headline')"
            />
            <h2
                v-else-if="error"
                class="text-xl md:text-4xl mt-4 mb-16 md:mt-10 md:mb-12"
            >
                <template v-if="error.statusCode === 404">
                    {{ $t("error.404.headline") }}
                </template>
                <template v-else>
                    {{ $t("error.generic.headline") }}
                </template>
            </h2>
            <p
                v-if="error"
                class="max-w-lg text-center md:mb-4"
            >
                <template v-if="error.statusCode === 404">
                    {{ $t("error.404.text") }}
                </template>
                <template v-else-if="error.statusCode >= 500">
                    {{ $t("error.500.text") }}
                </template>
                <template v-else>
                    {{ $t("error.generic.text") }}
                </template>
            </p>
        </div>

        <form-button
            element="link"
            type="primary"
            class="mt-8"
            @click="handleError"
        >
            {{ $t("error.button") }}
        </form-button>
    </div>
</template>

<script setup lang="ts">
import type { NuxtError } from "#app";

withDefaults(
    defineProps<{
        error?: NuxtError | null;
    }>(),
    {
        error: null,
    },
);

definePageMeta({
    title: "error.title",
});

const handleError = () => clearError({ redirect: "/" });
</script>
