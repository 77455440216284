<template>
    <svg
        :width="size"
        :height="size"
        stroke="currentColor"
        :viewBox="viewBox"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g class="spinner">
            <circle
                :cx="halfSize"
                :cy="halfSize"
                :r="radius"
                fill="none"
                :stroke-width="strokeWidth"
            />
        </g>
    </svg>
</template>

<script setup lang="ts">
const props = withDefaults(
    defineProps<{
        size?: number;
    }>(),
    {
        size: 24,
    },
);

const viewBox = computed(() => `0 0 ${props.size} ${props.size}`);
const halfSize = computed(() => props.size / 2);
const radius = computed(() => halfSize.value - 2);
const strokeWidth = 3;
</script>

<style scoped>
.spinner {
    transform-origin: center;
    animation: spinner-animation 2s linear infinite;
}

.spinner circle {
    stroke-linecap: round;
    animation: spinner_YpZS 1.5s ease-in-out infinite;
}

@keyframes spinner-animation {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes spinner_YpZS {
    0% {
        stroke-dasharray: 0 150;
        stroke-dashoffset: 0;
    }
    47.5% {
        stroke-dasharray: 42 150;
        stroke-dashoffset: -16;
    }
    95%,
    100% {
        stroke-dasharray: 42 150;
        stroke-dashoffset: -59;
    }
}
</style>
