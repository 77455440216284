import payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M from "/build/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/build/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/build/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/build/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/build/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/build/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/build/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/build/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/build/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/build/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/build/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_pctIVG_FA3ygvTkx4z_pYtRdHdDEZ_qPbl4DqvN9CMQ from "/build/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_LbMGQtAFFPgI0RURn765nCDysqXXHqYcxtaB3wYs_tU from "/build/node_modules/nuxt3-meta-pixel/dist/runtime/plugin.mjs";
import plugin_client_cWQMPgYRW0BWZIw2GExtJ2gkroIp4Vr3ZCOt1HoJd68 from "/build/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw from "/build/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U from "/build/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM from "/build/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_qFoR2KwrfPbeYF1NbvQtNji8ub5JyA6jqzexZ5yyjV8 from "/build/node_modules/nuxt-auth-sanctum/dist/runtime/plugin.js";
import braze_vcPCy6C9uqFkKOozNusFpxZYuHgSrhiM1jXLkd9nW4k from "/build/plugins/braze.ts";
import clickOutside_3o9C8E1bis7Dt8rM9UIuFmfPNJn9ds4UUZG5YqTfcJ8 from "/build/plugins/clickOutside.ts";
import date_O2WDjWfn4qSYeHy9rBcY4LjfvRXfDrAlzsRv9gUm3PA from "/build/plugins/date.ts";
import environment__YWu8xwk_pMwj62R9No_rIdc5UzxP_Zt_OART1HvLPU from "/build/plugins/environment.ts";
import mitt_gX99hIqc_4Ds_oxpW9ZggFIQt_TwlHPZ_D1tG4_2Jpg from "/build/plugins/mitt.ts";
import recaptcha_vq_SqlhdLs4FUNHaAfRS_nsTXv2Kinr4Q_VI_3s7mjg from "/build/plugins/recaptcha.ts";
import sentry_mW3XGrtPCuZcBmJuVnT9v_bqG2TVSyf5dPs2Yj6o6FM from "/build/plugins/sentry.ts";
import ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs from "/build/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_pctIVG_FA3ygvTkx4z_pYtRdHdDEZ_qPbl4DqvN9CMQ,
  plugin_LbMGQtAFFPgI0RURn765nCDysqXXHqYcxtaB3wYs_tU,
  plugin_client_cWQMPgYRW0BWZIw2GExtJ2gkroIp4Vr3ZCOt1HoJd68,
  switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw,
  route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U,
  i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM,
  plugin_qFoR2KwrfPbeYF1NbvQtNji8ub5JyA6jqzexZ5yyjV8,
  braze_vcPCy6C9uqFkKOozNusFpxZYuHgSrhiM1jXLkd9nW4k,
  clickOutside_3o9C8E1bis7Dt8rM9UIuFmfPNJn9ds4UUZG5YqTfcJ8,
  date_O2WDjWfn4qSYeHy9rBcY4LjfvRXfDrAlzsRv9gUm3PA,
  environment__YWu8xwk_pMwj62R9No_rIdc5UzxP_Zt_OART1HvLPU,
  mitt_gX99hIqc_4Ds_oxpW9ZggFIQt_TwlHPZ_D1tG4_2Jpg,
  recaptcha_vq_SqlhdLs4FUNHaAfRS_nsTXv2Kinr4Q_VI_3s7mjg,
  sentry_mW3XGrtPCuZcBmJuVnT9v_bqG2TVSyf5dPs2Yj6o6FM,
  ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs
]