import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoAlert, LazySvgoArrowDown, LazySvgoArrowDownLight, LazySvgoArrowLeft, LazySvgoArrowRight, LazySvgoBurger, LazySvgoCheck, LazySvgoCheckCircle, LazySvgoCheckCircleWhite, LazySvgoCircle, LazySvgoEWallet, LazySvgoEye, LazySvgoEyeClosed, LazySvgoInfo, LazySvgoInfoBlack, LazySvgoMail, LazySvgoMailOpen, LazySvgoPdf, LazySvgoQrCode, LazySvgoStar, LazySvgoTimes } from '#components'
const lazyGlobalComponents = [
  ["SvgoAlert", LazySvgoAlert],
["SvgoArrowDown", LazySvgoArrowDown],
["SvgoArrowDownLight", LazySvgoArrowDownLight],
["SvgoArrowLeft", LazySvgoArrowLeft],
["SvgoArrowRight", LazySvgoArrowRight],
["SvgoBurger", LazySvgoBurger],
["SvgoCheck", LazySvgoCheck],
["SvgoCheckCircle", LazySvgoCheckCircle],
["SvgoCheckCircleWhite", LazySvgoCheckCircleWhite],
["SvgoCircle", LazySvgoCircle],
["SvgoEWallet", LazySvgoEWallet],
["SvgoEye", LazySvgoEye],
["SvgoEyeClosed", LazySvgoEyeClosed],
["SvgoInfo", LazySvgoInfo],
["SvgoInfoBlack", LazySvgoInfoBlack],
["SvgoMail", LazySvgoMail],
["SvgoMailOpen", LazySvgoMailOpen],
["SvgoPdf", LazySvgoPdf],
["SvgoQrCode", LazySvgoQrCode],
["SvgoStar", LazySvgoStar],
["SvgoTimes", LazySvgoTimes],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
